import React, { FC, useCallback, useState, useEffect } from "react";
import memoize from "utils/memoize";
import { useQuery } from "@apollo/client";
import EmailSignupDialog from "dialogs/EmailSignupDialog";
import { queryGetBrandSubscriptionsByEmail } from "graphql-client/queries/email-subscription";
import isEmpty from "lodash.isempty";
import { useDetectRouteChanged } from "hooks/useDetectRouteChanged";
import getCountryFromUrl from "utils/getCountryFromUrl";
import { useLocation } from "react-router";
import { usePageContentNoProxy } from "customProviders/LocationProvider";
import { useRhUserAtomValue } from "hooks/atoms";
import { useKeycloak } from "@react-keycloak/web";
import { useUserSessionAtomValue } from "hooks/atoms";
import useCheckEmailPopUp from "@RHCommerceDev/hooks/useCheckEmailPopUp/useCheckEmailPopUp";
import { COOKIE_WITH_SESSION } from "utils/constants";
import { processEnvServer } from "hooks/useSsrHooks";
import { getSourceCode } from "utils/getSourceCode";
import { useCookies } from "react-cookie";

const EmailSignupContainer: FC<{}> = () => {
  const { pageContent } = usePageContentNoProxy();

  const { keycloak } = useKeycloak();
  const { loading: loadingSession } = useUserSessionAtomValue();

  const country = getCountryFromUrl();
  const { pathname } = useLocation();
  const { email } = useRhUserAtomValue();
  const {
    renderEmailSignup: showEmailSignUpPopup = false,
    emailPopupData = {},
    setSessionCookie = () => {},
    resetCookieClicked
  } = useCheckEmailPopUp();

  const [emailSignUpDialogOpen, setEmailSignUpDialogOpen] = useState(false);

  const [cookies] = useCookies([
    "userAcceptedAllCookies",
    "cookie_preferenes_banner_opened"
  ]);
  const userAcceptedAllCookies = cookies["userAcceptedAllCookies"] === "true";
  const isBannerOpened = cookies["cookie_preferenes_banner_opened"] === "true";
  const isEmailPopUpClosed = !processEnvServer
    ? sessionStorage.getItem("EMAIL_POPUP_CLOSED") === "true"
    : false;

  const isDisabledPath = useCallback(pathName => {
    return (
      !pathName.includes(".jsp") ||
      pathName.includes("store-locations") ||
      pathName.includes("trade-sales/membership-application.jsp") ||
      pathName.includes("/checkout") ||
      pathName.includes("/error")
    );
  }, []);

  const handleCookieChange = useCallback(
    pathName => {
      const isExcludePath = isDisabledPath(pathName);
      if (!isExcludePath && !keycloak?.authenticated) {
        if (isEmpty(emailPopupData)) {
          setSessionCookie?.(COOKIE_WITH_SESSION, {
            ...emailPopupData,
            clickCount: 1
          });
        } else {
          if (!emailPopupData?.isPopClosed) {
            let count = Number(emailPopupData?.clickCount || 1) + 1;
            setSessionCookie?.(COOKIE_WITH_SESSION, {
              ...emailPopupData,
              clickCount: count
            });
          }
        }
      }
    },
    [isDisabledPath, keycloak?.authenticated, emailPopupData, setSessionCookie]
  );

  useDetectRouteChanged({ handleCookieChange });

  const { loading: loadingBrandsSubscriptions } = useQuery<Query>(
    queryGetBrandSubscriptionsByEmail,
    {
      skip: isEmpty(email) || isDisabledPath(pathname),
      variables: {
        email,
        country,
        sourceCode: getSourceCode({
          place: "EMAIL_SIGN_UP_CONTAINER"
        })
      },
      onCompleted: data => {
        const subscribedBrands = Object.keys(
          data?.getBrandSubscriptionsByEmail || {}
        ).filter(
          k => data?.getBrandSubscriptionsByEmail[k] && k !== "__typename"
        );
        const brandsIsSubscribed = subscribedBrands.includes("RH");
        if (brandsIsSubscribed) {
        }
      },
      fetchPolicy: "cache-and-network"
    }
  );

  useEffect(() => {
    const isExcludePath = isDisabledPath(pathname);

    if (!processEnvServer) {
      if (
        !keycloak?.authenticated &&
        !loadingSession &&
        !loadingBrandsSubscriptions &&
        showEmailSignUpPopup &&
        !isExcludePath &&
        !emailPopupData?.isPopClosed &&
        !isEmailPopUpClosed
      ) {
        if (
          Number(emailPopupData?.clickCount) >=
          Number(pageContent?.emailSignupContent?.CLICK_OPTION)
        ) {
          if (!isBannerOpened || (isBannerOpened && userAcceptedAllCookies)) {
            setTimeout(() => {
              setEmailSignUpDialogOpen(true);
            }, 1500);
          }
        }
      }
    }
  }, [
    keycloak?.authenticated,
    loadingBrandsSubscriptions,
    loadingSession,
    showEmailSignUpPopup,
    isDisabledPath,
    pageContent?.emailSignupContent?.CLICK_OPTION,
    emailPopupData,
    userAcceptedAllCookies
  ]);

  const handleCloseModal = useCallback(() => {
    sessionStorage.setItem("EMAIL_POPUP_CLOSED", "true");
    setEmailSignUpDialogOpen(false);
    resetCookieClicked?.();
  }, [setEmailSignUpDialogOpen, resetCookieClicked]);

  return (
    <>
      {emailSignUpDialogOpen && (
        <EmailSignupDialog
          open={emailSignUpDialogOpen}
          onCloseModal={handleCloseModal}
        />
      )}
    </>
  );
};

export default memoize(EmailSignupContainer);
