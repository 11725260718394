import { useEffect } from "react";
import { useHistory } from "react-router";
import { processEnvServer } from "@RHCommerceDev/hooks/useSsrHooks";

export function useDetectRouteChanged({ handleCookieChange }) {
  const history = useHistory();

  useEffect(() => {
    if (!processEnvServer) {
      // window.onpopstate = () => {
      //   if (handleCookieChange) {
      //     handleCookieChange(history?.location?.pathname);
      //   }
      // };
      window.addEventListener("beforeunload", () => {
        handleCookieChange(history?.location?.pathname);
      });
    }
  }, [handleCookieChange, history?.location?.pathname]);

  useEffect(() => {
    if (!processEnvServer) {
      // Listen for changes in the URL
      const unlisten = history.listen(() => {
        if (handleCookieChange) {
          handleCookieChange(history?.location?.pathname);
        }
      });

      // Cleanup the listener when the component unmounts
      return () => {
        unlisten();
      };
    }
  }, [history, handleCookieChange]);
}
